import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  forwardRef,
  Inject,
  Injector,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  faCalendar,
  faCaretLeft,
  faCaretRight,
  faInfoCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, skip } from 'rxjs/operators';
import { BaseLoggingService } from '../../../services/base-logging.service';
import { BaseComponent, UIControlSettings } from '../base/base.component';
import { PopoverService } from '../../../services';

enum DateView {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year',
}

type DateSettings = {
  initView: DateView;
  applyView: DateView;
  justifyCalendar: 'Left' | 'Right';
  alignCalendar: 'Top' | 'Bottom';
  format: string;
  yearPage: number;
  minDate: Date;
  maxDate: Date;
  disabledMonths: number[];
  outOfRange: 'Disable' | 'Warn';
  autoOpen: boolean;
  initDate?: Date;
  disableBar?: boolean;
};

const defaultDateSettings: DateSettings = {
  initView: DateView.Day,
  applyView: DateView.Day,
  justifyCalendar: 'Left',
  alignCalendar: 'Bottom',
  format: 'MM/dd/yyyy',
  yearPage: 9,
  minDate: null,
  maxDate: null,
  disabledMonths: [],
  outOfRange: 'Disable',
  autoOpen: false,
  initDate: new Date(),
  disableBar: false,
};

@Component({
  selector: 'date-v2',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true,
    },
  ],
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent extends BaseComponent {
  //#region Icons

  faCalendar = faCalendar;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faTimes = faTimes;
  faInfoCircle = faInfoCircle;

  //#endregion Icons;

  items: Array<any>;
  currentDate: Date = new Date();
  marginDays: number;
  page: number = 0;
  pageChanged: number = 0;
  private keepOpen: boolean = false;
  private popUpClosed$: Subject<boolean> = new Subject<boolean>();
  protected warningCallback: (value) => any;

  constructor(
    injector: Injector,
    cmpFactoryResolver: ComponentFactoryResolver,
    elRef: ElementRef,
    @Inject(BaseLoggingService) logService: BaseLoggingService,
    private popoverSvc: PopoverService
  ) {
    super(injector, cmpFactoryResolver, elRef, logService);
    this._settings = { ...this._settings, ...defaultDateSettings };
  }

  //#region ElementRefs

  @ViewChild('input') input: ElementRef;
  @ViewChild('tplWarn') warningTemplate: TemplateRef<unknown>;

  //#endregion

  //#region Input/Output

  /* @Input() source: Array<unknown>; */

  @Input()
  set settings(value: UIControlSettings & DateSettings) {
    for (const property in value) {
      this._settings[property] = value[property] ?? this._settings[property];
      this.currentDate = this.settings.initDate ?? new Date();
    }
    if (this._settings.e2eId) {
      this.attach_e2e_attributes(this._settings.e2eId);
    }
  }

  get settings() {
    return this._settings as UIControlSettings & DateSettings;
  }

  //#endregion

  //#region Component Specific

  select(value: number, key: number) {
    switch (this.settings.initView) {
      case DateView.Day: {
        this.currentDate = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          value
        );
        this.closeCalendar(this.currentDate);
        break;
      }
      case DateView.Month: {
        this.currentDate.setDate(1);
        this.currentDate.setMonth(key);
        if (this.settings.applyView == DateView.Month) {
          this.currentDate = new Date(
            this.currentDate.getFullYear(),
            key + 1,
            0
          );
          this.closeCalendar(this.currentDate);
        } else {
          this.settings.initView = DateView.Day;
        }
        break;
      }
      case DateView.Year: {
        this.currentDate.setFullYear(value);
        this.settings.initView = DateView.Month;
        break;
      }
    }
    this.updateItems();
  }

  scroll(dir: number) {
    switch (this.settings.initView) {
      case DateView.Day: {
        if (
          (this.currentDate.getMonth() == 0 && dir == -1) ||
          (this.currentDate.getMonth() == 11 && dir == 1)
        ) {
          this.currentDate.setMonth(11 - this.currentDate.getMonth());
          this.currentDate.setFullYear(this.currentDate.getFullYear() + dir);
        } else {
          this.currentDate.setMonth(this.currentDate.getMonth() + dir);
        }
        this.pageChanged++;
        break;
      }
      case DateView.Month: {
        this.currentDate.setFullYear(this.currentDate.getFullYear() + dir);
        this.pageChanged++;
        break;
      }
      case DateView.Year: {
        this.page += dir;
        break;
      }
    }
    this.updateItems();
  }

  changeView() {
    switch (this.settings.initView) {
      case DateView.Day: {
        this.settings.initView = DateView.Month;
        break;
      }
      case DateView.Month: {
        this.settings.initView = DateView.Year;
        break;
      }
    }
    this.updateItems();
  }

  private updateItems() {
    switch (this.settings.initView) {
      case DateView.Day: {
        let lastMonthDay = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth() + 1,
          0
        ).getDate();
        this.marginDays = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          1
        ).getDay();
        this.items = [
          ...Array.from(
            ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            (name, i) => {
              return { value: name };
            }
          ),
          ...Array.from(Array(this.marginDays), (_, i) => {
            return { value: null };
          }),
          ...Array.from(Array(lastMonthDay), (_, i) => {
            return {
              value: i + 1,
              isActive: this.isActive(
                this.currentDate.getFullYear(),
                this.currentDate.getMonth(),
                i + 1
              ),
            };
          }),
        ];
        break;
      }
      case DateView.Month: {
        this.marginDays = -7;
        this.items = [
          ...Array.from(Array(12), (_, i) => {
            return {
              value: new Date(
                this.currentDate.getFullYear(),
                i,
                1
              ).toLocaleDateString('en-US', { month: 'short' }),
              isActive: this.isActive(this.currentDate.getFullYear(), i),
            };
          }),
        ];
        break;
      }
      case DateView.Year: {
        this.marginDays = -7;
        let startYear =
          Math.floor(this.currentDate.getFullYear() / 10) * 10 + this.page * 10;
        this.items = [
          ...Array.from(Array(this.settings.yearPage + 1), (_, i) => {
            return {
              value: startYear + i,
              isActive: this.isActive(startYear + i),
            };
          }),
        ];
        break;
      }
    }
  }

  private isActive(year: number, month?: number, day?: number) {
    if (this.settings.outOfRange !== 'Disable') return true;
    return (
      this.isInRange(year, month, day) &&
      !this.settings.disabledMonths?.includes(month)
    );
  }

  private isInRange(year: number, month?: number, day?: number) {
    let noMin = isNaN(this.settings.minDate?.getTime());
    let noMax = isNaN(this.settings.maxDate?.getTime());

    if (day === undefined && month === undefined) {
      return (
        (noMin || year >= this.settings.minDate.getFullYear()) &&
        (noMax || year <= this.settings.maxDate.getFullYear())
      );
    } else if (day === undefined) {
      let minMonth = !noMin
        ? this.settings.minDate.getFullYear() * 10000 +
          this.settings.minDate.getMonth() * 100
        : undefined;
      let maxMonth = !noMax
        ? this.settings.maxDate.getFullYear() * 10000 +
          this.settings.maxDate.getMonth() * 100
        : undefined;
      let checkMonth = year * 10000 + month * 100;

      return (
        (noMin || checkMonth > minMonth) && (noMax || checkMonth < maxMonth)
      );
    } else {
      let minDate = !noMin
        ? this.settings.minDate.getFullYear() * 10000 +
          (this.settings.minDate.getMonth() + 1) * 100 +
          this.settings.minDate.getDate()
        : undefined;
      let maxDate = !noMax
        ? this.settings.maxDate.getFullYear() * 10000 +
          (this.settings.maxDate.getMonth() + 1) * 100 +
          this.settings.maxDate.getDate()
        : undefined;
      let checkDate = year * 10000 + (month + 1) * 100 + day;

      return (noMin || checkDate > minDate) && (noMax || checkDate < maxDate);
    }
  }

  private closeCalendar(value?: Date) {
    if (
      this.settings.outOfRange !== 'Warn' ||
      !value ||
      this.isInRange(value.getFullYear(), value.getMonth(), value.getDate())
    ) {
      super.focusout(value);
      this.popUpClosed$.next(true);
      return;
    }
    this.warnCall(
      this.warningTemplate,
      this.input.nativeElement,
      () => {
        this.focusout(value);
      },
      () => {
        this.focusout();
      }
    );
    this.popUpClosed$.next(true);
  }

  public warnCall(
    content: TemplateRef<unknown>,
    origin: HTMLElement,
    okCallBack: Function,
    cancelCallBack: Function
  ) {
    this.warningCallback = (isOk) => {
      (isOk && okCallBack()) || (!isOk && cancelCallBack());
      this.popoverSvc.close();
    };
    let options = {
      content: content,
      origin: origin,
      width: '300px',
      data: null,
      height: '200px',
      settings: { hasBackdrop: true },
    };
    this.popoverSvc.open(options);
  }

  //#endregion

  //#region Overrides

  focusin(force?: boolean) {
    this.currentDate = (this.value as Date) ?? new Date();
    this.updateItems();
    if (force) {
      this.input.nativeElement.focus();
    }
    super.focusin();
    fromEvent(document, 'click')
      .pipe(takeUntil(this.popUpClosed$), skip(1))
      .subscribe((event: PointerEvent) => {
        const pathList = event.composedPath();
        this.keepOpen =
          pathList?.length > 0 &&
          (pathList as Array<HTMLElement>).some((el) => {
            return (
              el.className &&
              typeof el.className === 'string' &&
              el.className?.includes('ui-calendar')
            );
          });
        if (!this.keepOpen) {
          this.closeCalendar();
        }
      });
  }

  //#endregion
}
