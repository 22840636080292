import {
  ApiMicroService,
  Environment,
  ENVIRONMENT,
  ModelState,
} from '@aksia-monorepo/shared-ui';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Stream } from '../classes/stream/stream.model';
import {
  StreamSourceEnum,
  StreamPeriodicity,
  EntityTypeEnum,
  DataProviderEnum,
  PublicReturnFeeEnum,
  PublicReturnGeneralClassificationEnum,
} from '../enums/enums';
import { IErrorResult } from '../interfaces/system.interface';
import { tap } from 'rxjs/operators';

export const PERIODIC_STREAMS = {
  AUM: 'aum',
  PUBLICRETURNS: 'publicreturns',
  NETIRR: 'netIRR',
  GROSSIRR: 'grossIRR',
  NETMOIC: 'netMOIC',
  GROSSMOIC: 'grossMOIC',
  NETDPI: 'netDPI',
  NETRVPI: 'netRVPI',
  INVESTED_CAPITAL: 'investedCapital',
  NETINCOMERETURN: 'netIncomeReturn',
  GROSSINCOMERETURN: 'grossIncomeReturn',
  NETAPPRECIATIONRETURN: 'netAppreciationReturn',
  GROSSAPPRECIATIONRETURN: 'grossAppreciationReturn',
  TOTALNETRETURN: 'totalNetReturn',
  TOTALGROSSRETURN: 'totalGrossReturn',
};

export const PERIODIC_STREAMS_DISPLAY = {
  aum: 'AUM',
  publicreturns: 'Public Returns',
  netIRR: 'Net IRR',
  grossIRR: 'Gross IRR',
  netMOIC: 'Net TVPI',
  grossMOIC: 'Gross TVPI',
  netDPI: 'Net DPI',
  netRVPI: 'Net RVPI',
  investedCapital: 'Invested Capital',
  netIncomeReturn: 'Net Income Return',
  grossIncomeReturn: 'Gross Income Return',
  netAppreciationReturn: 'Net Appreciation Return',
  grossAppreciationReturn: 'Gross Appreciation Return',
  totalNetReturn: 'Total Net Return',
  totalGrossReturn: 'Total Gross Return',
};

export type PeriodicStreamType =
  typeof PERIODIC_STREAMS[keyof typeof PERIODIC_STREAMS];

export interface IPeriodicStreamPoint {
  asOf: string;
  value: number;
  source: StreamSourceEnum;
}

export interface IPublicReturnStreamPoint extends IPeriodicStreamPoint {
  feeType: PublicReturnFeeEnum;
  classification: PublicReturnGeneralClassificationEnum;
  isPublic: boolean;
}

export interface IPeriodicStreamMinMaxPoint {
  fundId: number;
  min: IPeriodicStreamPoint;
  max: IPeriodicStreamPoint;
  periodicity: StreamPeriodicity;
}

export interface IPeriodicStream {
  fundId: number;
  periodicity: StreamPeriodicity;
  datapoints: Array<IPeriodicStreamPoint>;
}

export interface IPeriodicStreamMinMax {
  netirr: IPeriodicStreamMinMaxPoint;
  grossirr: IPeriodicStreamMinMaxPoint;
  netmoic: IPeriodicStreamMinMaxPoint;
  grossmoic: IPeriodicStreamMinMaxPoint;
  netdpi: IPeriodicStreamMinMaxPoint;
  netrvpi: IPeriodicStreamMinMaxPoint;
  investedcapital: IPeriodicStreamMinMaxPoint;
  netIncomeReturn: IPeriodicStreamMinMaxPoint;
  grossIncomeReturn: IPeriodicStreamMinMaxPoint;
  netAppreciationReturn: IPeriodicStreamMinMaxPoint;
  grossAppreciationReturn: IPeriodicStreamMinMaxPoint;
  totalNetReturn: IPeriodicStreamMinMaxPoint;
  totalGrossReturn: IPeriodicStreamMinMaxPoint;
}

export interface IPeriodicStreamAsOf {
  netirr: IPeriodicStreamPoint;
  grossirr: IPeriodicStreamPoint;
  netmoic: IPeriodicStreamPoint;
  grossmoic: IPeriodicStreamPoint;
  netdpi: IPeriodicStreamPoint;
  netrvpi: IPeriodicStreamPoint;
  investedcapital: IPeriodicStreamPoint;
  netIncomeReturn: IPeriodicStreamMinMaxPoint;
  grossIncomeReturn: IPeriodicStreamMinMaxPoint;
  netAppreciationReturn: IPeriodicStreamMinMaxPoint;
  grossAppreciationReturn: IPeriodicStreamMinMaxPoint;
  totalNetReturn: IPeriodicStreamMinMaxPoint;
  totalGrossReturn: IPeriodicStreamMinMaxPoint;
}

@Injectable({
  providedIn: 'root',
})
export class PeriodicStreamsService {
  cachedReturns: Map<string, Array<IPublicReturnStreamPoint>> = new Map();

  constructor(
    private api: ApiMicroService,
    @Inject(ENVIRONMENT) private env: Environment
  ) {}

  public getSpecificStream(
    entityId: number,
    periodicStreamType: PeriodicStreamType
  ): Observable<IPeriodicStream | null> {
    return this.api.get(
      `periodicdata/returnprofile/${periodicStreamType}/${entityId}`
    );
  }

  public getAllStreamMinMaxPoints(
    entityId: number
  ): Observable<IPeriodicStreamMinMax | null> {
    return this.api.get(
      `periodicdata/returnprofile/allreturnprofilestreams/${entityId}/stream/asofrange`
    );
  }

  public getAllStreamAsOfPoints(
    entityId: number,
    asOf: string
  ): Observable<IPeriodicStreamAsOf | null> {
    return this.api.get(
      `periodicdata/returnprofile/allreturnprofilestreams/${entityId}/stream/datapoint?asOf=${asOf}`
    );
  }

  public getYTDCalculation(
    entityId: number,
    entityTypeId: EntityTypeEnum,
    stream: Array<Stream>
  ): Observable<unknown | null> {
    let dto = [
      ...(this.cachedReturns.get(
        PERIODIC_STREAMS.PUBLICRETURNS
      ) as Array<IPublicReturnStreamPoint>),
    ];
    stream?.forEach((streamPoint) => {
      let plainStreamPoint = this.stripPublicReturnPoint(streamPoint.toPlain());
      plainStreamPoint.value = plainStreamPoint.value / 100;

      if (
        streamPoint.isNew &&
        streamPoint.value !== undefined &&
        streamPoint.value !== null
      ) {
        dto.push(plainStreamPoint);
      } else if (streamPoint.markedForDeletion) {
        dto = dto.filter(
          (point) =>
            !(
              new Date(point.asOf).getFullYear() ===
                new Date(plainStreamPoint.asOf).getFullYear() &&
              new Date(point.asOf).getMonth() ===
                new Date(plainStreamPoint.asOf).getMonth()
            )
        );
      } else {
        let cachedAsOf = dto.find(
          (cachedPoint) =>
            new Date(cachedPoint.asOf).getFullYear() ===
              new Date(plainStreamPoint.asOf).getFullYear() &&
            new Date(cachedPoint.asOf).getMonth() ===
              new Date(plainStreamPoint.asOf).getMonth()
        );
        if (
          cachedAsOf &&
          plainStreamPoint.value !== undefined &&
          plainStreamPoint.value !== null &&
          streamPoint.state !==
            ModelState.Ready /*cachedAsOf.value !== plainStreamPoint.value*/
        ) {
          dto.push(plainStreamPoint);
        }
      }
    });
    return this.api.post(
      `periodicdata/publicreturns/actual/${DataProviderEnum.Ostrako_O2}@${entityId}_${entityTypeId}/stream/calculateytd`,
      dto
    );
  }

  public getHistoricalStream(
    entityId: number,
    periodicStreamType: PeriodicStreamType,
    entityTypeId?: EntityTypeEnum
  ): Observable<unknown | null> {
    let apiStreamURI =
      periodicStreamType === PERIODIC_STREAMS.PUBLICRETURNS
        ? `publicreturns/actual/${DataProviderEnum.Ostrako_O2}@${entityId}_${entityTypeId}/stream/rawwithytd`
        : `returnprofile/${periodicStreamType}/${entityId}`;
    return this.api.get(`periodicdata/${apiStreamURI}`).pipe(
      tap((result) => {
        let cachedStream = result.datapoints?.map((point) => ({ ...point }));

        this.cachedReturns.set(periodicStreamType, cachedStream);
        return result;
      })
    );
  }

  public updateStream(
    entityId: number,
    stream: Array<Stream>,
    periodicStreamType: PeriodicStreamType,
    periodicity: StreamPeriodicity = StreamPeriodicity.Monthly
  ): Observable<Array<Stream | IErrorResult>> {
    const streamPlain =
      periodicStreamType === PERIODIC_STREAMS.PUBLICRETURNS
        ? stream.map((streamPoint) => {
            let plainPoint = streamPoint.toPlain();
            plainPoint.value = !streamPoint.markedForDeletion
              ? plainPoint.value / 100
              : 0;

            return this.stripPublicReturnPoint(plainPoint);
          })
        : stream.map((streamPoint) => {
            let plainPoint = streamPoint.toPlain();
            let {
              classType,
              currency,
              entityId,
              entityTypeId,
              entityIds,
              relation,
              effectiveAsOfDate,
              feeType,
              classification,
              estimationAsOf,
              isNew,
              isPublic,
              isAUM,
              modifiedOn,
              ...privatePoint
            } = plainPoint;
            return privatePoint;
          });
    let apiStreamURI =
      periodicStreamType === PERIODIC_STREAMS.PUBLICRETURNS
        ? `publicreturns/actual/${entityId}/stream/edit`
        : `returnprofile/${periodicStreamType}/${entityId}/stream/edit?periodicity=${periodicity}`;
    return this.api.put(`periodicdata/${apiStreamURI}`, streamPlain);
  }

  public uploadStream(
    entityId,
    investmentProgramId,
    payload
  ): Observable<Array<Stream | IErrorResult>> {
    let apiStramURI = `publicreturns/actual/${investmentProgramId}/stream/upload?fundId=${entityId}`;
    return this.api.postFile(`periodicdata/${apiStramURI}`, payload);
  }

  private stripPublicReturnPoint(plainPoint: Stream) {
    let {
      classType,
      currency,
      entityId,
      entityTypeId,
      entityIds,
      relation,
      effectiveAsOfDate,
      isNew,
      isPublic,
      isAUM,
      modifiedOn,
      ...publicPoint
    } = plainPoint;
    return publicPoint as unknown as IPublicReturnStreamPoint;
  }
}
